import ModalInfo from "@/classes/ModalInfo";

export default class Modal {

    constructor() {

        this.active = false;

        this.type = '';
        this.currentModal = new ModalInfo();

        this.help = new ModalInfo();
        this.info = new ModalInfo();
        this.share = new ModalInfo();
        this.privacy = new ModalInfo();
        this.imprint = new ModalInfo();
        this.renew = new ModalInfo();

        this.buttonImprint = '';
        this.buttonPrivacy = '';

        this.buttonAgain = '';
        this.buttonContinue = '';

        this.buttonFacebook = '';
        this.buttonTwitter = '';
        this.buttonLinkedIn = '';
        this.buttonWhatsapp = '';
        this.buttonMail = '';
        
    }

    openCustom(title, text, type) {

        this.type = type;
        this.currentModal = new ModalInfo();
        this.currentModal.text = text;
        this.currentModal.title = title;
        this.setActive(true);

    }

    open(type) {

        this.setActive(true);

        let modal;

        switch(type) {
            case 'info' : modal = this.info; break;
            case 'imprint' : modal = this.imprint; break;
            case 'privacy' : modal = this.privacy; break;
            case 'help' : modal = this.help; break;
            case 'share' : modal = this.share; break;
            case 'renew' : modal = this.renew; break;
        }

        this.currentModal = modal;
        this.type = type;

    }

    close() {

        this.setActive(false);

    }

    setActive(active) {

        document.querySelectorAll("#modal .inner")[0].scrollTop = 0;
        this.active = active;

    }

    setHelp(content) {

        this.help = content;

    }

    showImprint() {

        this.setActive(true);
        this.currentModal = this.imprint;
        this.type = "imprint";

    }

    showPrivacy() {

        this.setActive(true);
        this.currentModal = this.privacy;
        this.type = "privacy";

    }

    build(construct) {
        
        let jInfo = construct.info;
        this.info.text = jInfo.screen.text;
        this.info.title = jInfo.screen.title;
        this.info.headline = jInfo.screen.headline;

        let jImprint = construct.imprint;
        this.imprint.title = '<b>' + jImprint.screen.title + '</b>';
        this.imprint.text = jImprint.screen.text;

        let jPrivacy = construct.privacy;
        this.privacy.title = '<b>' + jPrivacy.screen.title + '</b>';
        this.privacy.text = jPrivacy.screen.text;

        let jRenew = construct.renew;
        this.renew.title = jRenew.screen.title;
        this.renew.text = jRenew.screen.text;
        this.buttonAgain = jRenew.screen.button_again;
        this.buttonContinue = jRenew.screen.button_continue;

        this.buttonImprint = jImprint.screen.title;
        this.buttonPrivacy = jPrivacy.screen.title;

        let jShare = construct.social;
        this.share.title = jShare.screen.title;
        this.share.text = jShare.screen.text;

        this.buttonFacebook = jShare.screen.facebook;
        this.buttonTwitter = jShare.screen.twitter;
        this.buttonLinkedIn = jShare.screen.linkedin;
        this.buttonWhatsapp = jShare.screen.whatsapp;
        this.buttonMail = jShare.screen.mail;

    }

}