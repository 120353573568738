<template>

  <div>
    
      <div class="table">
        <div class="col">
          <div class="header space-bottom-md">
            <span class="text-title title title-1 font-weight-bold color-black">
              {{test.setTitle}} {{data.index + 1}}
            </span>
            <span class="line"></span>
            <span class="text-title title title-2 color-black">
              {{data.title}}
            </span>
          </div>
          <div class="ani-text--3 text-area color-black" v-html="data.text"></div>
        </div>
        <div class="col">
          <img class="space-bottom-md image ani-img--4"
                  :src="data.image.sizes.full"
                  :srcset="data.image.srcset"
                  v-if="data.image !== ''" />
          <div class="progress">
            <div class="progress-line"
                 :style="{width: (test.currentSet) * 100 / (test.sets.length - 1) + '%'}">
              <div :style="{transitionDuration: (test.currentSet * 0.5) + 's'}"
                   class="progress-line-inner"></div>
            </div>
            <div class="progress-dots">
              <div class="progress-dot"
                   :style="{color:item.color, transitionDelay: (index * 0.5) + (1.5) + 's'}"
                   :key="index"
                   :class="[index === test.currentSet ? 'active' : '', index <= test.currentSet ? 'was' : '']"
                   v-for="(item, index) in test.sets">
                <div :style="{animationDelay: (index * 0.5) + (1.5) + 's'}"
                     class="progress-dot-inner"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>

</template>

<script>
export default {
  name: "SetTeaser",
  props: [
    'index'
  ],
  computed: {
    test() {
      return this.$store.state.controller.test;
    },
    data() {
      return this.$store.state.controller.test.sets[this.index];
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/set-teaser';
@import '~@/styles/table';
</style>