import store from '../store';
import Start from "@/classes/Start";
import Teaser from "@/classes/Teaser";
import Modal from "@/classes/Modal";
import Test from "@/classes/Test";
import ModalImage from "@/classes/ModalImage";
import Result from "@/classes/Result";

export default class Controller {

    constructor() {

        this.version = 0;
        this.state = "";

        this.start = new Start();
        this.teaser = new Teaser();
        this.test = new Test();
        this.result = new Result();

        this.modal = new Modal();
        this.imgmodal = new ModalImage();

    }

    setState(state) {

        _paq.push(['setCustomUrl', '/' + state]);
        _paq.push(['setDocumentTitle', state]);
        _paq.push(['trackPageView']);

        this.state = state;

        if(this.state === "result") {

            this.result.start();

        } else if(this.state === 'test') {

            _paq.push(['setCustomUrl', '/test/' + this.test.sets[this.test.currentSet].id]);
            _paq.push(['setDocumentTitle', this.test.sets[this.test.currentSet].id]);
            _paq.push(['trackPageView']);

        }

        this.resize();

    }

    resize() {

        let resizeEvent = window.document.createEvent('UIEvents');
        resizeEvent.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(resizeEvent);

    }

    build(construct) {

        this.version = construct.version;
        this.modal.build(construct.modal);
        this.start.build(construct.start);
        this.teaser.build(construct.teaser);
        this.test.build(construct.test);
        this.result.build(construct.result);

        setTimeout(function(){

            let user = store.state.user.getStorage();

            if(user != null && user.version === this.version) {

                this.modal.open("renew");

            } else {

                store.state.user.resetStorage();

            }

            this.setState("start");

        }.bind(this), 0.1);
        
    }

}