import QuestionItem from "@/classes/QuestionItem";

export default class QuestionTypeImagesItem extends QuestionItem {

    constructor() {

        super();
        this.images = "";
        this.image = "";

    }

    build(construct, index, question) {

        super.build(construct, index, question);
        this.images = construct.images;
        this.image = construct.image;

        this.correct = [];
        let i = 0;
        while(i < this.images.length) {

            let image = this.images[i];
            

            if(image.correct) {

                this.correct.push(i);

            }

            i = i + 1;

        }

        this.getCorrectAnswerTitles();

    }

    isCorrect() {

        if(this.userAnswers !== '__NONE__' && this.userAnswers[0] !== '__NONE__') {

            let correctAnswers = this.getUserCorrectAnswers();
            if (correctAnswers > 0) {

                return true;

            }

        }

        return false;

    }

    getUserCorrectAnswers() {

        let userCorrectAnswers = 0;

        let i = 0;
        let userAnswers = this.userAnswers;
        while(i < userAnswers.length) {

            let userAnswer = userAnswers[i];

            let correctAnswers = this.correct;
            let j = 0;
            while(j < correctAnswers.length) {

                let correctAnswer = correctAnswers[j];

                if(correctAnswer === userAnswer) {

                    userCorrectAnswers = userCorrectAnswers + 1;

                    break;

                }

                j = j + 1;

            }

            i = i + 1;

        }

        return userCorrectAnswers;

    }

    getCorrectAnswerTitles() {

        let correct = [];

        let i = 0;
        while(i < this.images.length) {

            let image = this.images[i];
            if(image.correct) {

                correct.push(image.title);

            }

            i = i + 1;

        }

        if(correct.length > 0) {

            return correct.join(", ");

        }

        return "";

    }


    getUserAnswerTitles() {

        return this.images[this.userAnswers[0]].title;

    }

}