<template>

  <div id="app" :class="{
    'is-start' : controller.state === 'start',
    'is-test' : controller.state === 'test',
    'is-result' : controller.state === 'result',
    'is-teaser' : controller.state === 'teaser'
  }" :style="{color: controller.state === 'test' ? test.sets[test.currentSet].color : ''}">

    <!--<button @click="debug()" class="color-black" style="position: absolute; z-index: 100; left: 0; top: 0;
    border: none; opacity: 0.2;">DEBUG</button>-->

    <header id="app-header" class="app-section">
      <div id="header">
        <div id="header-inner">
          <div class="result-details-head" v-show="
          controller.state === 'result' &&
          controller.result.state === 'details'">
            <div class="head-table">
              <div class="head-col head-col-1">
                <div class="ani-text--1 text-title space-bottom-xs" v-html="controller.result.detailTitle"></div>
                <div class="ani-text--1 text-standard-great" v-html="controller.result.detailText"></div>
              </div>
              <div class="head-col head-col-2">
                <button class="button result-average-button has-title is-small"
                        :class="{'active' :controller.result.showAverage}"
                        @click="controller.result.switchShowAverage()">
                  <span class="title">{{controller.result.buttonAverage}}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="share-buttons buttons-cell" v-show="
          controller.state === 'result' &&
          controller.result.state === 'start'">
            <div class="ani-button--2 button-outer">
              <a :href="controller.modal.buttonMail" target="_blank" class="button has-icon">
                <span class="icon icon-share"></span>
              </a>
            </div>
            <div class="ani-button--3 button-outer">
              <a :href="controller.modal.buttonFacebook" target="_blank" class="button has-icon">
                <span class="icon icon-facebook"></span>
              </a>
            </div>
            <div class="ani-button--4 button-outer">
              <a :href="controller.modal.buttonTwitter" target="_blank" class="button has-icon">
                <span class="icon icon-twitter"></span>
              </a>
            </div>
            <div class="ani-button--4 button-outer">
              <a :href="controller.modal.buttonLinkedIn" target="_blank" class="button has-icon">
                <span class="icon icon-linkedin"></span>
              </a>
            </div>
            <div class="ani-button--4 button-outer">
              <a :href="controller.modal.buttonWhatsapp" target="_blank" class="button has-icon">
                <span class="icon icon-whatsapp"></span>
              </a>
            </div>
            <button @click="modal.open('info')" class="button has-icon ani-button--5">
              <span class="icon icon-info"></span>
            </button>
          </div>
          <div class="result-tabs" v-show="controller.state === 'result' && controller.result.state === 'evaluation'">
            <transition-group name="ts-section" tag="div" class="result-tabs-group">
              <div class="result-tabs-button-wrapper"
                   :key="item.id"
                   v-for="item in controller.test.sets">
                <button class="result-tabs-button"
                        @click="controller.result.setEvaluationSet(item.index)"
                        :class="{'active' : item.index === controller.result.currentEvaluationSet}">
                  <img :src="item.icon.sizes.full" class="result-tabs-icon" />
                  <span class="result-tabs-button-title">{{item.title}}</span>
                </button>
              </div>
            </transition-group>
          </div>
          <div class="questions-titles color-black" v-show="controller.state !== 'result'">
            <transition-group name="ts-section" tag="div">
              <div class="question-title"
                   :key="item.id"
                   v-show="controller.state === 'test'
                       && controller.test.currentSet === item.index
                       && item.state === 'questions'"
                   v-for="item in controller.test.sets"
                   :index="item.index">
                <transition-group name="ts-section"  tag="div">
                  <div class="question-title-inner"
                       :key="item2.id"
                       v-show="item2.index === item.currentQuestion"
                       v-for="item2 in item.questions"
                       :index="item2.index">
                    <div class="question-title-num text-standard">
                      {{item2.num}}
                      /
                      {{controller.test.num -1}}
                    </div>
                    <div class="ani-text--1 question-title-text text-standard font-weight-bold"
                         v-html="item2.getQuestionTitle()">
                    </div>
                  </div>
                </transition-group>
              </div>
            </transition-group>
          </div>
          <div class="buttons">
            <transition name="ts-button">
              <button v-show="
                   controller.state !== ''
                   && controller.state !== 'start'
                   && controller.state !== 'teaser'
                    && controller.state !== 'result'
                   && controller.test.sets[controller.test.currentSet].state !== 'teaser'"
                      @click="modal.open('help')"
                      class="button has-ani has-icon">
                <span class="icon icon-help"></span>
              </button>
            </transition>
            <transition name="ts-button">
              <button @click="modal.open('share')" class="button has-ani has-icon"
                      v-show="controller.state !== 'result'">
                <span class="icon icon-share"></span>
              </button>
            </transition>
            <button @click="modal.open('info')" class="button info-button has-icon">
              <span class="icon icon-info"></span>
            </button>
          </div>
        </div>
      </div>
    </header>

    <transition-group tag="div" id="app-main" name="ts-section" class="app-section">
      <Start ref="start" key="appStart" v-show="controller.state === 'start'"
             class="section ani-section"></Start>
      <Teaser ref="teaser" key="appTeaser" v-show="controller.state  === 'teaser'"
              class="section ani-section"></Teaser>
      <Test ref="test" key="appTest" v-show="controller.state  === 'test'"
            class="section ani-section"></Test>
      <Result ref="result" key="appResult" v-show="controller.state  === 'result'"
              class="section ani-section"></Result>
    </transition-group>


    <footer id="app-footer">
      <div id="footer">
        <transition-group tag="div" name="ts-section" id="footer-inner" class="app-section">
          <div key="footerTeaser" class="buttons" v-show="controller.state === 'teaser'">
            <div class="ani-button--2 buttons-cell">
              <button class="button has-title"
                      @click="teaserPrevSlide()">
                <span class="title">{{teaser.buttonPrev }}</span>
              </button>
            </div>
            <div class="ani-button--3 buttons-cell">
              <button class="button has-title a__space-bottom__md"
                      @click="teaserNextSlide()">
                  <span class="title">{{teaser.currentSlideIndex < teaser.slides.length - 1 ?
                      teaser.buttonNext : teaser.buttonStart}}
                  </span>
              </button>
            </div>
          </div>
          <div key="footerTestTeaser" class="buttons" v-show="controller.state === 'test' &&
          test.sets[test.currentSet].state === 'teaser'">
            <div class="ani-button--2 buttons-cell">
              <button class="button has-title" @click="testTeaserPrev()">
                <span class="title">{{test.buttonPrev}}</span>
              </button>
            </div>
            <div class="ani-button--3 buttons-cell">
              <button class="button has-title" @click="testTeaserNext()">
                <span class="title">{{test.buttonNext}}</span>
              </button>
            </div>
          </div>
          <div key="footerTest" class="buttons" v-show="controller.state === 'test' &&
          test.sets[test.currentSet].state === 'questions'">
            <div class="ani-button--2 buttons-cell prev-question-small">
              <button class="button prev-question-small has-icon" @click="questionPrev()">
                <span class="icon icon-next"></span>
              </button>
            </div>
            <div class="ani-button--2 buttons-cell prev-question">
              <button class="button has-title" @click="questionPrev()">
                <span class="title">{{test.buttonPrev}}</span>
              </button>
            </div>
            <div class="ani-button--3 buttons-cell">
              <button class="button has-title" @click="questionSkip()" :class="{'none' : currentItem.userAnswers.length > 0 && currentItem.userAnswers[0] ===
                '__NONE__'}">
                <span class="title text-transform-none">{{test.buttonIgnore}}</span>
              </button>
            </div>
            <div class="ani-button--4 buttons-cell next-question">
              <button class="button has-title" :disabled="currentItem.userAnswers.length <= 0"
                      @click="questionNext()">
                <span class="title">{{test.buttonNext}}</span>
              </button>
            </div>
            <div class="ani-button--4 buttons-cell next-question-small ">
              <button class="button has-icon" :disabled="currentItem.userAnswers.length <= 0"
                      @click="questionNext()">
                <span class="icon icon-next"></span>
              </button>
            </div>
          </div>
          <div key="result" class="buttons buttons-outer buttons-result" v-show="controller.state === 'result'">
            <div class="share-buttons buttons-cell hide-mobile"
                 v-show="controller.result.state === 'start' || controller.result.state === 'details'">
              <div class="ani-button--2 button-outer">
                <a :href="controller.modal.buttonMail" target="_blank" class="button has-icon">
                  <span class="icon icon-share"></span>
                </a>
              </div>
              <div class="ani-button--3 button-outer">
                <a :href="controller.modal.buttonFacebook" target="_blank" class="button has-icon">
                  <span class="icon icon-facebook"></span>
                </a>
              </div>
              <div class="ani-button--4 button-outer">
                <a :href="controller.modal.buttonTwitter" target="_blank" class="button has-icon">
                  <span class="icon icon-twitter"></span>
                </a>
              </div>
              <div class="ani-button--4 button-outer">
                <a :href="controller.modal.buttonLinkedIn" target="_blank" class="button has-icon">
                  <span class="icon icon-linkedin"></span>
                </a>
              </div>
              <div class="ani-button--4 button-outer">
                <a :href="controller.modal.buttonWhatsapp" target="_blank" class="button has-icon">
                  <span class="icon icon-whatsapp"></span>
                </a>
              </div>
            </div>

            <div class="buttons-cell hide-mobile"
                 v-show="controller.result.state === 'start'">
              <button class="button has-title"
                      @click="controller.result.setState('details')">
                <span class="title">{{controller.result.buttonDetails}}</span>
              </button>
            </div>

            <div class="buttons-cell hide-mobile"
                 v-show="controller.result.state === 'details'">
              <button class="button has-title"
                      @click="controller.result.setState('evaluation')">
                <span class="title">{{controller.result.buttonEvaluation}}</span>
              </button>
            </div>

            <div class="info-buttons buttons-cell"
                 v-show="controller.result.state === 'start' || controller.result.state === 'details'">
              <button class="button has-title" @click="controller.result.getPdf()">
                <span class="title">{{controller.result.buttonDownload}}</span>
              </button>
            </div>

            <div class="nav-buttons buttons-cell"
                 v-show="controller.result.state === 'evaluation'">

              <div class="buttons buttons-outer">

                <div class="nav-button-prev buttons-cell show-mobile">
                  <button class="button has-icon" @click="controller.result.prevEvaluationQuestion()">
                    <span class="icon icon-next"></span>
                  </button>
                </div>

                <div class="nav-button-prev buttons-cell hide-mobile">
                  <button class="button has-title" @click="controller.result.prevEvaluationQuestion()">
                    <span class="title">{{controller.result.buttonEvaluationPrev}}</span>
                  </button>
                </div>

                <div class="buttons-cell nav-dots"
                     v-if="controller.result.getEvaluationQuestions(controller.result.currentEvaluationSet).length > 1">
                  <button class="nav-button"
                          @click="controller.result.setEvaluationQuestion(item.index)"
                          :class="{'active' : index === controller.result.currentEvaluationQuestion}"
                          v-for="(item, index) in
                          controller.result.getEvaluationQuestions(controller.result.currentEvaluationSet)"
                          :key="item.id">
                  </button>
                </div>

                <div class="nav-button-next buttons-cell hide-mobile">
                  <button class="button has-title" @click="controller.result.nextEvaluationQuestion()">
                    <span class="title">{{controller.result.buttonEvaluationNext}}</span>
                  </button>
                </div>

                <div class="nav-button-next buttons-cell show-mobile">
                  <button class="button has-icon"
                          v-show="controller.result.state === 'evaluation'"
                          @click="controller.result.nextEvaluationQuestion()">
                    <span class="icon icon-next"></span>
                  </button>
                </div>

              </div>

            </div>

          </div>
        </transition-group>
      </div>
    </footer>

    <transition name="ts-modal">
      <Modal key="modal" v-show="modal.active" id="modal"></Modal>
    </transition>

    <transition name="ts-modal">
      <ModalImage key="imgModal" v-show="imgmodal.active && imgmodal.isLoaded" id="modal-image"></ModalImage>
    </transition>

  </div>

</template>

<script>
import Start from './components/Start.vue'
import Teaser from './components/Teaser.vue'
import Modal from './components/Modal.vue'
import Test from './components/Test.vue'
import Set from './components/Set.vue'
import SetTeaser from './components/SetTeaser.vue'
import Result from "./components/Result";
import ModalImage from "@/components/ModalImage";

export default {
  name: 'App',
  components: {
    ModalImage,
    Result,
    Start,
    Teaser,
    Modal,
    Test,
    Set,
    SetTeaser
  },
  computed: {
    test() {
      return this.$store.state.controller.test;
    },
    teaser() {
      return this.$store.state.controller.teaser;
    },
    modal() {
      return this.$store.state.controller.modal;
    },
    imgmodal() {
      return this.$store.state.controller.imgmodal;
    },
    controller() {
      return this.$store.state.controller;
    },
    currentItem() {
      let test = this.$store.state.controller.test;
      let set = test.sets[test.currentSet];
      let question = set.questions[set.currentQuestion];
      let item = question.items[question.currentItem];
      return item;
    }
  },

  methods: {
    testTeaserPrev() {
      this.test.sets[this.test.currentSet].prev();
    },
    testTeaserNext() {
      this.test.sets[this.test.currentSet].next();
    },
    teaserPrevSlide() {
      this.teaser.prevSection();
      this.$refs.teaser.$refs.teaserSlide.splide.go("-");
    },
    teaserNextSlide() {
      this.teaser.nextSection();
      this.$refs.teaser.$refs.teaserSlide.splide.go("+");
    },
    questionPrev() {
      this.test.sets[this.test.currentSet].questions[
          this.test.sets[this.test.currentSet].currentQuestion
          ].prev();
    },
    questionSkip() {
      this.test.sets[this.test.currentSet].questions[
          this.test.sets[this.test.currentSet].currentQuestion
          ].skip();
    },
    questionNext() {
      this.test.sets[this.test.currentSet].questions[
          this.test.sets[this.test.currentSet].currentQuestion
          ].next();
    },
    debug() {
      this.controller.test.getUserPoints();
    }
  }
}
</script>

<style lang="scss">
//@import '~@/styles/webfont';
@import '~@/styles/modernize';
@import '~@/styles/style';
@import '~@/styles/header';
@import '~@/styles/footer';
@import '~@/styles/button';
@import '~@/styles/text';
@import '~@/styles/image';
@import '~@/styles/spacing';
@import '~@/styles/animation';
@import '~@/../node_modules/mobile-drag-drop/default.css';
@import '~@/../node_modules/@splidejs/splide/dist/css/themes/splide-default.min.css';
</style>
