<template>
  <section>

    <div class="middle">
      <div class="header">
          <div class="ani-text--1 text-title header-title space-bottom-xs" v-html="data.evaluationTitle"></div>
          <transition-group name="ts-section" tag="div" class="group">
              <div class="header-set"
                   v-show="index === data.currentEvaluationSet"
                   :key="item.id"
                   v-for="(item, index) in test.sets">
                <transition-group name="ts-section" tag="div">
                  <div class="header-question"
                       v-show="index2 === data.currentEvaluationQuestion"
                       :key="item2.id"
                       v-for="(item2, index2) in data.getEvaluationQuestions(item.index)">
                    <div class="ani-text--1 text-standard-great header-question-title space-bottom-md">
                        <span class="header-question-title-num">
                            {{ item2.num + "." }}
                        </span>
                        <span class="header-question-title-text font-weight-bold"
                            v-html="item2.getQuestionTitleWithoutDep()"></span>
                    </div>
                  </div>
                </transition-group>
              </div>
          </transition-group>
      </div>

      <div class="main">
        <transition-group name="ts-section" tag="div" class="group">
          <div class="main-set"
               v-show="index === data.currentEvaluationSet"
               :key="item.id"
               v-for="(item, index) in test.sets">
              <transition-group name="ts-section" tag="div">
              <div class="main-question"
                   v-show="index2 === data.currentEvaluationQuestion"
                   :key="item2.id"
                   v-if="item2.isVisible()"
                   v-for="(item2, index2) in data.getEvaluationQuestions(item.index)">
                <div class="main-items" :style="{'color' : item.color}">
                  <div class="main-items-table">
                    <div class="main-items-head main-items-row">
                      <div class="main-items-cell cell-1">
                        <div class="text-title-smaller text-transform-uppercase color-black"
                             v-html="data.evaluationTable1"></div>
                      </div>
                      <div class="main-items-cell cell-2">
                        <div class="text-title-smaller text-transform-uppercase color-black"
                             v-html="data.evaluationTable2"></div>
                      </div>
                      <div class="main-items-cell cell-3">
                        <div class="text-title-smaller text-transform-uppercase color-black"
                             v-html="data.evaluationTable3"></div>
                      </div>
                    </div>
                    <div class="main-items-row"
                         :key="item3.id"
                         v-if="item3.isVisible()"
                         @click="data.openModal(item3)"
                         :style="{'transitionDelay' : ((index3 + 1) * 50) + 'ms'}"
                         v-for="(item3, index3) in data.getEvaluationItems(item.index, item2.index)">
                      <div class="main-items-cell cell-1">
                        <button class="button has-icon is-small">
                          <span class="icon icon-help"></span>
                        </button>
                        <div class="text-standard title color-black" v-html="item3.text"></div>
                      </div>
                      <div class="main-items-cell cell-2">
                         <div class="test-standard color-black">{{item3.getCorrectAnswerTitles()}}</div>
                      </div>
                      <div class="main-items-cell cell-3">
                          <div class="button is-small has-icon">
                           <span class="icon"
                                 :class="{'icon-correct' : item3.isCorrect(), 'icon-close' : !item3.isCorrect()}">
                           </span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </transition-group>
          </div>
        </transition-group>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "ResultEvaluation",
  computed: {
    test() {
      return this.$store.state.controller.test;
    },
    data() {
      return this.$store.state.controller.result;
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@/styles/result-evaluation';
</style>