<template>
  
  <div :class="'type-' + data.type" class="color-black">
    <div class="underlay"
         @click="data.close()">
    </div>
    <div class="inner">
      <div class="content">
        <div @click="data.close()" class="button has-icon button-close">
          <span class="icon icon-close"></span>
        </div>
        <div class="text-title space-bottom-sm"
             v-if="data.currentModal.title !== ''"
             v-html="data.currentModal.title"></div>
        <div class="text-headline headline font-weight-bold space-bottom-sm"
             v-if="data.currentModal.headline !== ''"
             v-html="data.currentModal.headline"></div>
        <div class="text-standard text-html"
             v-if="data.currentModal.text !== ''"
             v-html="data.currentModal.text"></div>
        <div class="footer"
             v-if="data.type === 'info'">
          <button class="button is-link text-standard-small"
                  @click="data.showImprint()">
            {{data.buttonImprint}}
          </button>
          <button class="button is-link text-standard-small"
                  @click="data.showPrivacy()">
            {{data.buttonPrivacy}}
          </button>
        </div>
        <div class="footer"
             v-if="data.type === 'renew'">
          <button class="button is-link text-standard-small"
                  @click="continueTest()">
            {{data.buttonContinue}}
          </button>
          <button class="button is-link text-standard-small"
                  @click="resetUser()">
            {{data.buttonAgain}}
          </button>
        </div>
        <div class="footer"
             v-if="data.type === 'share'">
          <a :href="data.buttonMail" class="button has-icon">
            <span class="icon icon-share"></span>
          </a>
          <a :href="data.buttonFacebook" class="button has-icon">
            <span class="icon icon-facebook"></span>
          </a>
          <a :href="data.buttonTwitter" class="button has-icon">
            <span class="icon icon-twitter"></span>
          </a>
          <a :href="data.buttonLinkedIn" class="button has-icon">
            <span class="icon icon-linkedin"></span>
          </a>
          <a :href="data.buttonWhatsapp" class="button has-icon">
            <span class="icon icon-whatsapp"></span>
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Modal',
  computed: {
    user() {
      return this.$store.state.user;
    },
    data () {
      return this.$store.state.controller.modal;
    }
  },
  methods: {
    resetUser() {
      this.user.resetStorage();
      this.data.close();
    },
    continueTest() {
      this.$store.state.controller.test.continueTest();
      this.data.close();
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/modal';
</style>
